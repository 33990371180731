<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游广岛必备的景点套票，精选广岛山多个人气设施与美食店家，一週内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始使用，所有票劵也将同步开通 。(<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfhiroshima/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfhiroshima/zh/ferry_SC.png'),
          title: ['广岛港-吴港 游轮单程票'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '1.广岛港宇品港湾大楼1F窗口','地址：广岛市南区宇品海岸１丁目13-26','2.吴港（中央码头）航站楼1F窗口','地址：吴市宝町4-44'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/cruise/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可用路线：','<br>・广岛港→吴港<br>・吴港→广岛港','<br>※单程票只适合用于其中一段路线。'] },
                { cid: 2, text: ['如果您未在窗口进行二维码验证，将无法登船，请注意。'] },
                { cid: 3, text: ['不提供广岛到吴的高速船（超级喷射艇）服务。'] },
                { cid: 4, text: ['请参阅官方网站上的时刻表。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfhiroshima/zh/ferry2_SC.jpg'),
          title: ['广岛港-宫岛港 高速游轮 1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '1.广岛港宇品港湾大楼1F ①or②号窗口','地址：广岛市南区宇品海岸１丁目13-26','2.广岛格兰王子大饭店旅游咨询台','地址：〒734-0012 广岛市南区元宇品町23-1','3.宫岛港3号码头售票处','地址：廿日市市宫岛町胡町地先'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/highspeedship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可用路线：','<br>・广岛港→宫岛港<br>・王子酒店前→宫岛港<br>・宫岛港→广岛港<br>・宫岛港→王子酒店前','<br>※您可以在以上任一路段享受1000日元的优惠券。'] },
                { cid: 2, text: ['如果您未在窗口进行二维码验证，将无法登船，请注意。'] },
                { cid: 3, text: ['请参阅官方网站上的时刻表。'] },
                { cid: 4, text: ['费用若有差额需要支付的话，请在现场缴交。'] },
                { cid: 5, text: ['可能因客满而无法乘坐，敬请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfhiroshima/zh/Orizuru_SC.png'),
          title: ['纸鹤塔门票 1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒730-0051 广岛县广岛市中区大手町一丁目2番1号(1F 咨询柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.orizurutower.jp/zh-CN/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请尽情使用1000日元优惠券。'] },
                { cid: 2, text: ['有可能因活动等而临时休馆，详细情况请在出发前至官网确认。'] },
                { cid: 3, text: ['【关于再入场】','<br>・仅限入场当天，可多次重覆入场。<br>・需要再次入场的话，请于出口告知工作人员，并在手上盖章。再次入场需要确认入场券和印章。'] },
                { cid: 4, text: ['【使用展望台】','<br>・为了防止在馆内发生事故，可能会拒绝携带行李箱或大件行李等本馆认为不合适的物品进入。有投币式储物柜（1楼入口旁边/收费），请使用。<br>・严禁携带在馆外购买的饮食品入场。<br>・严禁使用三脚架拍照，以免给其他客人带来麻烦。'] },
                { cid: 5, text: ['有可能因天气原因无法完全观赏到风景、或因强风等而关闭的状况，敬请注意。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfhiroshima/zh/Peacecle_SC.png'),
          title: ['广岛市共用自行车「Peacecle」1日券 【广岛市内】'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒732-0828　广岛市南区京桥町2-29　SS大楼1F(Peacecle运营事务所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://docomo-cycle.jp/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['其他一日券柜台不受理二维码认证。'] },
                { cid: 2, text: ['当日23:59前有效。'] },
                { cid: 3, text: ['自行车仅限身高145cm以上者使用。'] },
                { cid: 4, text: ['请归还至市内140个自行车专用停放处之一。'] },
                { cid: 5, text: ['部分自行车的使用/归还时间有限制。详情请查看官网。'] },
                { cid: 6, text: ['骑自行车时必须戴安全帽。如果您需要安全帽这边提供付费出租。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfhiroshima/zh/ekie_SC.png'),
          title: ['JR广岛站前购物中心「ekie」1,500日圆礼券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒732-0822　广岛市南区松原町1-2 (2楼服务台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.ekie.jp/lang/ch.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['结帐时请在各店铺出示礼券。(出示二维码无法使用。请务必在使用前兑换成礼券。)'] },
                { cid: 2, text: ['本券不可兑换现金或找零。'] },
                { cid: 3, text: ['因临时休业、满员等原因不能使用的情况也不会退款。'] },
                { cid: 4, text: ['礼券对象外店铺：日本旅行、7-11 Heart in、伊达眼科诊所、红叶药局、广岛站大楼 歌野原牙科诊所、广岛站内科·儿童诊所、河合塾manabisu'] },
                { cid: 5, text: ['礼券(实体劵)有效期为7天。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfhiroshima/zh/Sozo_SC.png'),
          title: ['「广岛御好烧站前广场」1,500日圆礼券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒732-0822 广岛县广岛市南区松原町10-1 广岛Full Focus大楼6F「御好烧Sozo」'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://ekimae-hiroba.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['结账时，请在各店出示商品券（请务必先兑换成商品券，不可使用二维码支付）。'] },
                { cid: 2, text: ['不可兑换成现金。'] },
                { cid: 3, text: ['对于失窃或丢失，本公司概不负责。'] },
                { cid: 4, text: ['若临时休馆・客满等因素游客无法使用的话仍旧无法退款。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfhiroshima/zh/Bic_Camera_SC.png'),
          title: ['JR广岛站前家电量贩店「Bic Camera 广岛站前店」1000日圆礼券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒732-0822　广岛县广岛市南区松原町5-1　1楼综合咨询柜台'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.biccamera.com.c.lj.hpcn.transer-cn.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['结账时，请在各店出示商品券（请务必先兑换成商品券，不可使用二维码支付）。'] },
                { cid: 2, text: ['不可兑换成现金。'] },
                { cid: 3, text: ['对于盗窃、遗失或减失等情况，我们不负任何责任。'] },
                { cid: 4, text: ['不接受其他类型的商品券、明信片、邮票、香烟或其他我们指定以外的商品兑换。'] },
                { cid: 5, text: ['若因临时休业、客满等原因无法使用，依旧不提供退款。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfhiroshima/zh/LobbyLounge_SC.jpg'),
          title: ['广岛格兰比亚大酒店 大厅咖啡店 「Lobby Lounge」蛋糕套餐'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒732-0822 广岛县广岛市南区松原町1-5 大厅咖啡店 「Lobby Lounge」(大厅夹层)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/zh/hotels/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含:多种甜点师特製的蛋糕以及咖啡或红茶。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。','<br>※可能因临时休业・客满而无法使用。'] },
                { cid: 3, text: ['产品图片仅供参考。'] },
                { cid: 4, text: ['额外的费用均由客人承担。'] },
                { cid: 5, text: ['不可以外带。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfhiroshima/zh/dahe_SC.jpg'),
          title: ['大和博物馆入场券+馆内商店1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒737-0029　广岛县吴市宝町5番20号 (入口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://yamato-museum.com/leaflet/guidance-leaflet-simplified-chinese/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请于入馆时领取馆内1000日圆优惠券。'] },
                { cid: 2, text: ['1000日圆优惠券可在馆内商店使用，恕不找零。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFHiroshima&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>